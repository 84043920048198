.dn-viewport {
  height: 100%;
  width: 100%;
  min-height: 100px;
  position: relative;
  outline: none;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: overlay;
}
