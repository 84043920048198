.dn-corner-input {
  display: flex;
}
.dn-corner-input-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.dn-corner-input-cell {
  width: 26px;
  height: 26px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.6);
  margin: 5px;
}
.dn-corner-input-cell.active {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));
}
