/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-color-input {
  width: 100%;
}
.dn-color-input .ant-popover-inner-content {
  padding: 0 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dn-color-input-color-tips {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
