/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-flex-style-setter .dn-input-items {
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 10px;
  padding: 10px 10px 0 10px;
  background-color: #f0f0f0;
}
.dn-flex-style-setter .dn-input-items .ant-radio-group {
  display: flex;
  width: 100%;
}
.dn-flex-style-setter .dn-input-items .ant-radio-group .ant-radio-button-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 0;
}
