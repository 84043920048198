/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-node-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dn-node-actions-content {
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}
.dn-node-actions-content::before {
  position: absolute;
  content: '';
  display: block;
  height: 0;
  width: 300%;
  top: 50%;
  border-bottom: 1px dashed #f0f0f0;
  right: 100%;
}
.dn-node-actions-content::after {
  position: absolute;
  content: '';
  display: block;
  height: 0;
  width: 300%;
  top: 50%;
  border-bottom: 1px dashed #f0f0f0;
  left: 100%;
}
.dn-node-actions-content a {
  color: rgba(0, 0, 0, 0.45);
}
.dn-node-actions-content a:hover {
  color: #1890ff;
}
.dn-node-actions-item-text {
  font-size: 10px;
  display: flex;
  align-items: center;
  line-height: 1;
}
.dn-node-actions-item-text .dn-icon {
  margin-right: 6px;
}
