.dn-ghost {
  padding-left: 25px;
  padding-right: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color: var(--dn-ghost-color);
  font-size: 12px;
  z-index: 9999;
  border-radius: 50px;
  background-color: var(--dn-ghost-bg-color);
  pointer-events: none;
  left: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
}
