/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-position-input {
  display: flex;
  flex-direction: column;
}
.dn-position-input-row {
  display: flex;
  justify-content: center;
}
.dn-position-input-cell {
  width: 26px;
  height: 26px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.6);
  margin: 5px;
  color: rgba(0, 0, 0, 0.85);
}
.dn-position-input-cell.active {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));
}
