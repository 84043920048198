/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-input-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
}
.dn-input-items-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.85);
}
.dn-input-items-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  flex-shrink: 0;
  flex-grow: 0;
  color: rgba(0, 0, 0, 0.85);
}
.dn-input-items-item-controller {
  min-width: 0;
  flex-shrink: 1;
  flex-grow: 1;
}
.dn-input-items-item-controller .ant-radio-group {
  display: flex;
}
.dn-input-items-item-controller .ant-radio-group .ant-radio-button-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.dn-input-items-item.vertical {
  flex-direction: column;
  align-items: flex-start;
}
.dn-input-items-item.vertical .dn-input-items-item-controller {
  width: 100%;
}
