/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}
.animate__animated {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.animate__fadeInUp {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.dn-settings-form-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.dn-settings-form-wrapper .dn-node-path {
  flex-grow: 0;
}
.dn-settings-form-wrapper .dn-settings-form-content {
  flex-grow: 1;
  overflow: overlay;
}
.dn-settings-form-wrapper .ant-formily-item {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.dn-settings-form-wrapper .ant-formily-item * {
  font-size: 13px;
}
.dn-settings-form-wrapper .ant-formily-item .ant-formily-item-control-content-component {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dn-settings-form-wrapper .ant-formily-item .ant-formily-item-control-content-component > .ant-radio-group {
  display: flex !important;
  width: 100%;
}
.dn-settings-form-wrapper .ant-formily-item .ant-formily-item-control-content-component > .ant-radio-group .ant-radio-button-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 6px !important;
  align-items: center;
  flex-grow: 2;
}
.dn-settings-form-wrapper .ant-formily-item .ant-formily-item-control-content-component > .ant-slider {
  flex-shrink: 0;
  min-width: 0;
  width: 100%;
}
.dn-settings-form-wrapper .ant-formily-item .ant-formily-item-control-content-component > .ant-select {
  max-width: 140px;
}
.dn-settings-form {
  padding: 0 20px;
}
.dn-settings-form-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: #888;
}
