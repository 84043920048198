.dn-monaco-input {
  position: relative;
  transition: all 0.15s ease-in-out;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
}
.dn-monaco-input.loaded {
  opacity: 1;
}
.dn-monaco-input-view {
  flex-grow: 2;
  height: 100%;
}
.dn-monaco-input-helper {
  position: absolute;
  bottom: 10px;
  right: 5%;
  z-index: 2;
}
.dn-monaco-input .monaco-error-highline {
  left: 0px !important;
  width: 5px !important;
  height: 5px !important;
  background: red !important;
  border-radius: 100% !important;
  transform: translate(35px, 5px) !important;
}
