.dn-collapse-item {
  flex-wrap: wrap;
}
.dn-collapse-item-header {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  margin: -1px -20px 0 -20px;
  color: var(--dn-collapse-header-color);
  border-top: 1px solid var(--dn-panel-border-color);
  border-bottom: 1px solid var(--dn-panel-border-color);
  background-color: var(--dn-panel-active-bg-color);
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  font-size: 13px;
}
.dn-collapse-item-header-expand {
  margin-left: -13px;
  transform: rotate(-90deg);
  font-size: 12px;
  transition: all 0.15s ease-in-out;
  margin-right: 3px;
}
.dn-collapse-item-content {
  display: none;
  width: 100%;
  padding-top: 4px;
}
.dn-collapse-item-content > div:last-child {
  border-bottom: none !important;
}
.dn-collapse-item.expand .dn-collapse-item-content {
  display: block;
}
.dn-collapse-item.expand .dn-collapse-item-header-expand {
  transform: rotate(0);
}
