/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-node-path {
  padding: 4px 10px !important;
  border-bottom: 1px solid var(--dn-panel-border-color);
}
.dn-node-path .dn-icon {
  font-size: 11px;
}
.dn-node-path .ant-breadcrumb-separator {
  margin: 0 4px !important;
}
.dn-node-path a {
  font-size: 12px;
}
