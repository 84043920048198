/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-reactions-setter {
  width: 100%;
  min-height: 623px;
  overflow: hidden;
}
.dn-reactions-setter ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.dn-reactions-setter ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.dn-reactions-setter ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.dn-reactions-setter .ant-collapse {
  border: 1px solid #f0f0f0;
}
.dn-reactions-setter .ant-collapse-header {
  padding: 8px 10px !important;
  background-color: #fff !important;
  border-bottom: 1px solid #f0f0f0 !important;
  font-weight: 500 !important;
}
.dn-reactions-setter .ant-collapse-header .ant-collapse-arrow {
  margin-right: 4px !important;
}
.dn-reactions-setter .ant-collapse-item {
  border: none !important;
}
.dn-reactions-setter .ant-collapse-content {
  border: none !important;
  transition: none !important;
}
.dn-reactions-setter .ant-collapse-content-box {
  padding: 12px !important;
}
.dn-reactions-setter .reaction-runner .ant-collapse-content-box {
  padding: 12px 0 !important;
}
.dn-reactions-setter .reaction-state .ant-collapse-content-box {
  padding: 12px 0 !important;
}
.dn-reactions-setter .dn-field-property-setter {
  display: flex;
  height: 300px;
}
.dn-reactions-setter .dn-field-property-setter-coder-wrapper {
  display: flex;
  flex-grow: 2;
  height: 100%;
  padding-left: 10px;
  position: relative;
  flex-direction: column;
}
.dn-reactions-setter .dn-field-property-setter-coder-start {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 300;
  flex-grow: 0;
  opacity: 0.96;
  height: 31px;
}
.dn-reactions-setter .dn-field-property-setter-coder-end {
  font-size: 18px;
  height: 31px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 30px;
  font-weight: 300;
  flex-grow: 0;
  opacity: 0.96;
}
.dn-reactions-setter .dn-field-property-setter-coder {
  min-width: 0;
  flex-grow: 2;
  padding-left: 10px;
}
