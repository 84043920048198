.dn-empty {
  transform: perspective(1px) translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dn-empty-bg-color);
}
.dn-empty .animations {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dn-empty .hotkeys-list {
  text-align: center;
  line-height: 30px;
  color: #888;
}
