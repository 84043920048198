/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-drawer-setter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: var(--dn-composite-panel-tabs-content-bg-color);
  z-index: 10;
  display: flex;
  flex-direction: column;
}
.dn-drawer-setter-header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}
.dn-drawer-setter-header-text {
  margin-left: 4px;
}
.dn-drawer-setter-body {
  padding: 10px 20px;
  overflow: overlay;
  overflow-x: hidden;
  flex-grow: 2;
}
.dn-drawer-wrapper {
  transition: all 0.16s ease-in-out;
}
.dn-drawer-wrapper-enter {
  transform: translateX(100%);
}
.dn-drawer-wrapper-enter-active {
  transform: translateX(0);
}
.dn-drawer-wrapper-exit {
  transform: translateX(0);
}
.dn-drawer-wrapper-exit-active {
  transform: translateX(100%);
}
