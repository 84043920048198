.dn-data-source-setter ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.dn-data-source-setter ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.dn-data-source-setter ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.dn-data-source-setter-node-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dn-data-source-setter-node-title-icon {
  transition: all 0.15s ease-in-out;
  opacity: 0;
}
.dn-data-source-setter-node-title-icon:hover {
  color: #1890ff;
}
.dn-data-source-setter-layout {
  display: flex;
  justify-content: space-around;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
}
.dn-data-source-setter-layout .ant-tree-treenode {
  padding-right: 10px;
  white-space: nowrap;
}
.dn-data-source-setter-layout .ant-tree-treenode:hover .dn-data-source-setter-node-title-icon {
  opacity: 1;
}
.dn-data-source-setter-layout-item {
  position: relative;
}
.dn-data-source-setter-layout-item.left {
  width: 40%;
  border-right: 1px solid #f0f0f0;
}
.dn-data-source-setter-layout-item.right {
  width: 60%;
}
.dn-data-source-setter-layout-item-header {
  display: flex;
  flex: none;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 8px 12px 9px;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.dn-data-source-setter-layout-item-content {
  padding: 2%;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}
