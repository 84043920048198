/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-poly-input {
  display: flex;
  width: 100%;
  align-items: center;
}
.dn-poly-input .dn-poly-input-content {
  flex-grow: 2;
  margin-right: 2px;
  display: flex;
}
.dn-poly-input .dn-poly-input-content .ant-select {
  width: 100%;
}
.dn-poly-input .dn-poly-input-content .ant-input-number {
  width: 100%;
}
.dn-poly-input .dn-poly-input-controller {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
}
