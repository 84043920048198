.dn-border-style-setter {
  display: flex;
}
.dn-border-style-setter-position {
  margin-left: -10px;
}
.dn-border-style-setter-input {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}
.border-style-solid-line {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}
.border-style-solid-line::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  height: 0;
  width: 100%;
  border-top: 2px solid currentColor;
  transform: translateY(-50%);
}
.border-style-dashed-line {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}
.border-style-dashed-line::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  height: 0;
  width: 100%;
  border-top: 2px dashed currentColor;
  transform: translateY(-50%);
}
.border-style-dotted-line {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}
.border-style-dotted-line::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  height: 0;
  width: 100%;
  border-top: 2px dotted currentColor;
  transform: translateY(-50%);
}
