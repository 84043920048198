.dn-droppable-placeholder {
  height: 60px;
  background-color: var(--dn-droppable-bg-color);
  border: 1px dashed var(--dn-droppable-border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dn-droppable-color);
  font-weight: lighter;
  font-size: 13px;
}
