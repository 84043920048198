/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-fold-item .ant-formily-item-label-content {
  overflow: visible;
}
.dn-fold-item-base {
  cursor: pointer;
}
.dn-fold-item-title {
  display: flex;
  align-items: center;
  margin-left: -10px;
  cursor: pointer;
}
.dn-fold-item-title .dn-icon {
  transform: translateX(-3px);
}
.dn-fold-item-title .dn-icon svg {
  transition: all 0.15s ease-in-out;
  transform: rotate(-90deg);
}
.dn-fold-item-title.expand .dn-icon svg {
  transform: rotate(0deg);
}
.dn-fold-item-extra {
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 10px;
  padding: 10px 10px 0 10px;
  background-color: var(--dn-composite-panel-highlight-bg-color);
}
